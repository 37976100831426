import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
  Login,
  Logout,
  ResetAuthErrors,
  SetCurrentUser,
  UpdateUserName,
  UpdateUserStatus,
  ValidateUser,
  GetPerPages,
  GetRules,
  GetLoginUseMicrosoftUrl,
  UpdateUserSsn,
  LoginByToken,
  GetEmployeeAnnualBonusSetting,
  UpdateEmployeeAnnualBonusSetting,
  GetEmployeeRaceTypesList,
  GetEmployeeEthnicityTypesList,
  GetEmployeeCanWorkTypesList,
} from './app.actions';
import { AuthService } from '../auth/auth.service';
import { catchError, tap, switchMap, take, finalize } from 'rxjs/operators';
import { EMPTY, Observable, of, Subscription } from 'rxjs';
import { SetUserSign } from '../dashboard/store/user-form/user-form.actions';
import { ResetAdminPanel } from '../dashboard/store/employees/employees.actions';
import { StaticDataService } from '../static-data.service';
import { rules as mockRules } from '../../environments/draft-rules';
import { environment } from '../../environments/environment';
import { IdTitleObject, RoleEnum, UserModel } from '../shared/models';
import { PopupFactoryService } from '../popups/popup-factory.service';
import { getDataFromLocalStorage, removeEmptyObjectValues } from '../shared/helpers/other';
import { AnnualBonusSettingModel } from '../shared/models/settings.models';

@State({
  name: 'app',
  defaults: {
    isLoading: false,
    annualBonusSetting: null,
    currentUser: null,
    perPages: null,
    perPagesV2: {},
    meta: {
      authForm: {
        error: '',
      },
    },
    oldToken: '',
    newToken: '',
    rules: {},
    microsoftRedirectUrl: null,
    raceTypes: [],
    ethnicityTypes: [],
    workTypes: [],
  },
})
@Injectable()
export class AppState {
  constructor(
    private auth: AuthService,
    private staticService: StaticDataService,
    private router: Router,
    private popup: PopupFactoryService,
  ) {}

  @Selector()
  static raceTypes(state): IdTitleObject[] {
    return state.raceTypes;
  }

  @Selector()
  static ethnicityTypes(state): IdTitleObject[] {
    return state.ethnicityTypes;
  }

  @Selector()
  static workTypes(state): IdTitleObject[] {
    return state.workTypes;
  }

  @Selector()
  static isLoading(state): boolean {
    return state.isLoading;
  }

  @Selector()
  static annualBonusSetting(state): AnnualBonusSettingModel {
    return state.annualBonusSetting;
  }

  @Selector()
  static user(state) {
    return removeEmptyObjectValues({
      ...state.currentUser?.account,
      email: state.currentUser?.email,
      roles: state.currentUser?.roles ?? [],
      showOnlyUnreadNotifications: state.currentUser?.showOnlyUnreadNotifications,
    });
  }

  @Selector()
  static currentUser(state) {
    return {
      ...state.currentUser,
    };
  }

  @Selector()
  static role(state): string {
    return (state.currentUser || {})?.roles[0]?.title || null;
  }

  @Selector()
  static perPages(state) {
    return {
      ...state.perPages,
    };
  }

  @Selector()
  static perPagesV2(state) {
    return {
      ...state.perPagesV2,
    };
  }

  @Selector()
  static rules(state) {
    return state.rules;
  }

  @Selector()
  static microsoftRedirectUrl(state) {
    return state.microsoftRedirectUrl;
  }

  @Selector()
  static statusFilterName(state) {
    return state.rules.patient.statusFilterName;
  }

  @Selector()
  static socDateFieldName(state) {
    return state.rules.admission.socDateFieldName;
  }

  static getModuleRules(moduleName: string) {
    return createSelector([AppState.rules], rules => rules[moduleName] ?? {});
  }

  @Action(Login)
  login(ctx: StateContext<any>, { credentials }: Login): Subscription {
    const state = ctx.getState();
    return this.auth
      .signIn(credentials)
      .pipe(
        switchMap(({ token }) => {
          if (token) {
            this.auth.getPagination(token).subscribe((perPages: Array<{ key: string; perPage: number }>) => {
              ctx.patchState({
                ...ctx.getState(),
                perPages,
                perPagesV2: perPages.reduce((acc, curr) => {
                  acc[curr.key] = curr.perPage;
                  return acc;
                }, {}),
              });
            });
            ctx.patchState({
              newToken: token,
            });
            return this.auth.getUser(token);
          }
        }),
        catchError(() => {
          ctx.patchState({
            meta: {
              ...state.meta,
              authForm: {
                errors: 'Error',
              },
            },
          });
          return of(null);
        }),
      )
      .subscribe({
        next: (user: UserModel) => {
          ctx.patchState({
            oldToken: user?.token,
          });
          ctx
            .dispatch(new SetCurrentUser(user))
            .pipe(take(1))
            .subscribe(() => {
              const redirectUrl = this.router.routerState.snapshot.root.queryParams['redirectUrl'] || '/dashboard';
              if (user?.roles?.[0]?.title !== RoleEnum.Employee && redirectUrl.includes('employee-dashboard')) {
                this.router.navigate(['/dashboard']);
                return;
              }
              this.router.navigateByUrl(redirectUrl).then(() => {
                if (this.router.url.includes('login')) {
                  this.router.navigate(['/dashboard']);
                }
              });
            });
        },
        error: () => {
          ctx.patchState({
            meta: {
              ...state.meta,
              authForm: {
                errors: 'Error',
              },
            },
          });
        },
      });
  }

  @Action(GetLoginUseMicrosoftUrl)
  getLoginUseMicrosoft(ctx: StateContext<any>): Subscription {
    return this.auth
      .getLoginUseMicrosoftUrl()
      .pipe(take(1))
      .subscribe({
        next: (res: { redirectUrl: string }) => {
          if (res?.redirectUrl) {
            ctx.patchState({
              microsoftRedirectUrl: res.redirectUrl,
            });
          }
        },
      });
  }

  @Action(LoginByToken)
  loginByToken(ctx: StateContext<any>, { token, redirectToDashboard }: LoginByToken): void {
    if (token) {
      const state = ctx.getState();
      this.auth.getPagination(token).subscribe((perPages: Array<{ key: string; perPage: number }>) => {
        ctx.patchState({
          ...ctx.getState(),
          perPages,
          perPagesV2: perPages.reduce((acc, curr) => {
            acc[curr.key] = curr.perPage;
            return acc;
          }, {}),
        });
      });
      ctx.patchState({
        newToken: token,
      });
      this.auth
        .getUser(token)
        .pipe(take(1))
        .subscribe({
          next: (user: UserModel) => {
            ctx.patchState({
              oldToken: user?.token,
            });
            ctx
              .dispatch(new SetCurrentUser(user))
              .pipe(take(1))
              .subscribe(() => {
                this.router.navigate(['/dashboard']);
              });
          },
          error: () => {
            if (redirectToDashboard) {
              this.router.navigate(['/dashboard']);
            } else {
              ctx.patchState({
                meta: {
                  ...state.meta,
                  authForm: {
                    errors: 'Error',
                  },
                },
              });
            }
          },
        });
    }
  }

  @Action(SetUserSign)
  setUserSign(ctx: StateContext<any>, { signUrl }: SetUserSign): void {
    const state = ctx.getState();
    ctx.patchState({
      currentUser: {
        ...state.currentUser,
        account: {
          ...state.currentUser?.account,
          signUrl,
        },
      },
    });
  }

  @Action(SetCurrentUser)
  setCurrentUser(ctx: StateContext<any>, { user }: SetCurrentUser): void {
    const state = ctx.getState();
    if (user) {
      const currentUser = {
        ...user,
        token: user?.token ? user.token : state.currentUser?.token,
        account: {
          ...user.account,
          name: user.account.name || (user.account.firstName || '') + ' ' + (user.account.lastName || ''),
        },
      };
      localStorage.setItem('user', JSON.stringify(user));
      ctx.patchState({
        currentUser,
      });
    }
  }

  @Action(UpdateUserStatus)
  updateUserStatus(ctx: StateContext<any>, { status }: UpdateUserStatus): void {
    const state = ctx.getState();
    ctx.patchState({
      currentUser: {
        ...state.currentUser,
        documentHistory: {
          ...(state.currentUser.documentHistory || {}),
          status,
        },
      },
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<any>): void {
    localStorage.clear();
    sessionStorage.clear();
    ctx.patchState({
      currentUser: null,
    });
    ctx.dispatch(new ResetAdminPanel());
    this.auth.setRedirectUrl(this.router.url);
    const navigationExtras: NavigationExtras = {
      queryParams: {
        redirectUrl: this.auth.getRedirectUrl(),
      },
    };
    this.router.navigate(['/login'], navigationExtras).then((): void => {
      this.popup.closePopup();
    });
  }

  @Action(UpdateUserName)
  updateUserName(ctx: StateContext<any>, { firstName, lastName }: UpdateUserName): void {
    const state = ctx.getState();
    ctx.patchState({
      currentUser: {
        ...state.currentUser,
        account: {
          ...state.currentUser?.account,
          name: `${firstName} ${lastName}`,
        },
      },
    });
  }

  @Action(ValidateUser)
  validateUser(ctx: StateContext<any>): Promise<any> {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const user: UserModel = getDataFromLocalStorage('user');
    const token: string = (user || {}).token || urlParams.get('jwt');
    if (token) {
      return this.auth
        .getUser(token)
        .pipe(
          tap(userData => {
            ctx.patchState({
              ...ctx.getState(),
              currentUser: {
                ...userData,
                account: {
                  ...userData.account,
                  name: userData.account.name || userData.account.firstName + ' ' + userData.account.lastName,
                },
                token,
              },
            });
          }),
          catchError(() => {
            localStorage.removeItem('user');
            return of(null);
          }),
        )
        .toPromise();
    } else {
      return Promise.resolve();
    }
  }

  @Action(GetPerPages)
  getPerPages(ctx: StateContext<any>): Subscription {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const user: UserModel = getDataFromLocalStorage('user');
    const token: string = (user || {}).token || urlParams.get('jwt');
    if (token) {
      return this.auth.getPagination(token).subscribe((perPages: Array<{ key: string; perPage: number }>) => {
        ctx.patchState({
          ...ctx.getState(),
          perPages,
          perPagesV2: perPages.reduce((acc, curr) => {
            acc[curr.key] = curr.perPage;
            return acc;
          }, {}),
        });
      });
    }
  }

  @Action(ResetAuthErrors)
  resetAuthErrors(ctx: StateContext<any>): void {
    const state = ctx.getState();
    ctx.patchState({
      meta: {
        ...state.meta,
        authForm: {
          ...state.meta.authForm,
          errors: '',
        },
      },
    });
  }

  @Action(GetRules)
  getRules(ctx: StateContext<any>) {
    return this.staticService.getRules().pipe(
      tap(rules => ctx.patchState({ rules: environment.mockRules ? mockRules : rules })),
      catchError(() => EMPTY),
    );
  }

  @Action(UpdateUserSsn)
  updateSsn(ctx: StateContext<any>, { ssn }): void {
    const state = ctx.getState();
    ctx.patchState({
      currentUser: {
        ...state.currentUser,
        account: {
          ...state.currentUser?.account,
          socialSecurity: ssn,
        },
      },
    });
  }

  @Action(GetEmployeeAnnualBonusSetting)
  getEmployeeAnnualBonusSetting(ctx: StateContext<any>): Observable<AnnualBonusSettingModel> {
    ctx.patchState({
      isLoading: true,
    });
    return this.staticService.getEmployeeAnnualBonusSetting().pipe(
      tap((res: AnnualBonusSettingModel) => {
        ctx.patchState({
          annualBonusSetting: res,
        });
      }),
      finalize(() => {
        ctx.patchState({
          isLoading: false,
        });
      }),
    );
  }

  @Action(UpdateEmployeeAnnualBonusSetting)
  updateEmployeeAnnualBonusSetting(ctx: StateContext<any>, { period, value }) {
    ctx.patchState({
      isLoading: true,
    });
    return this.staticService.updateEmployeeAnnualBonusSetting(period, value).pipe(
      tap((res: AnnualBonusSettingModel) => {
        ctx.patchState({
          annualBonusSetting: res || {
            one: 350,
            three: 500,
            two: 650,
            four: 750,
          },
        });
      }),
      finalize(() => {
        ctx.patchState({
          isLoading: false,
        });
      }),
    );
  }

  @Action(GetEmployeeRaceTypesList)
  getEmployeeRaceTypesList(ctx: StateContext<any>) {
    return ctx.getState().raceTypes?.length
      ? EMPTY
      : this.staticService.getEmployeeRaceTypesList().pipe(
          tap((raceTypes: IdTitleObject[]) => {
            ctx.patchState({
              raceTypes,
            });
          }),
        );
  }

  @Action(GetEmployeeEthnicityTypesList)
  getEmployeeEthnicityTypesList(ctx: StateContext<any>) {
    return ctx.getState().ethnicityTypes?.length
      ? EMPTY
      : this.staticService.getEmployeeEthnicityTypesList().pipe(
          tap((ethnicityTypes: IdTitleObject[]) => {
            ctx.patchState({
              ethnicityTypes,
            });
          }),
        );
  }

  @Action(GetEmployeeCanWorkTypesList)
  getEmployeeCanWorkTypesList(ctx: StateContext<any>) {
    return ctx.getState().workTypes?.length
      ? EMPTY
      : this.staticService.getEmployeeCanWorkTypesList().pipe(
          tap((workTypes: IdTitleObject[]) => {
            ctx.patchState({
              workTypes,
            });
          }),
        );
  }
}
